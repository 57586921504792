<!-- For greater than medium screen -->

<div data-aos="fade-up" data-aos-duration="600" data-aos-delay="600" data-aos-easing="ease-in-sine"
    data-aos-anchor-placement="top-bottom" data-aos-once="true" class="d-none d-md-block">

    <p class="heading-font-size">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-folder" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z" />
            <path fill-rule="evenodd"
                d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z" />
        </svg>
        &nbsp;
        Some Things I’ve Built
    </p>

    <app-my-git-repo-block-chain>
    </app-my-git-repo-block-chain>

    <hr />
    <br />

    <div class="row">
        <div class="col-md-6 col-lg-6" *ngFor="let gitRepo of myGitRepo">
            <div class="card" style="height: 96%;">
                <div class="card-body">
                    <p class="card-title text-font-size">
                        {{gitRepo.name}} &nbsp;
                        <a style="cursor: pointer;" class="text-success" target="_blank" href="{{gitRepo.repoLink}}">
                            <svg width="12" height="12" viewBox="0 0 16 16" class="bi bi-box-arrow-up-right"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                <path fill-rule="evenodd"
                                    d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                            </svg>
                        </a>
                    </p>
                    <p class="card-text text-font-size regular-ff" style="text-align: justify;">
                        {{gitRepo.description}}
                    </p>
                    <p class="card-text sub-text-font-size regular-ff font-color-ash">
                        {{gitRepo.language}}
                    </p>
                </div>
            </div>
            <br />
        </div>
    </div>

    <div style="text-align: center; margin-top: 10px;">
        <a type="button" class="btn btn-outline-success" href="https://github.com/iamchandanys?tab=repositories"
            target="_blank">
            View More
        </a>
    </div>

    <br /><br />
    <hr /><br /><br />

</div>

<!-- For Lesser than medium screen -->

<div data-aos="fade-up" data-aos-duration="600" data-aos-delay="600" data-aos-easing="ease-in-sine"
    data-aos-anchor-placement="top-bottom" data-aos-once="true" class="d-block d-md-none">

    <p class="heading-font-size">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-folder" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z" />
            <path fill-rule="evenodd"
                d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z" />
        </svg>
        &nbsp;
        Some Things I’ve Built
    </p>

    <br />

    <app-my-git-repo-block-chain>
    </app-my-git-repo-block-chain>

    <br />
    <hr />
    <br />

    <div class="col-12" style="padding-left: 0px !important; padding-right: 0px !important;"
        *ngFor="let gitRepo of myGitRepo">
        <div class="card">
            <div class="card-body">
                <p class="card-title text-font-size">
                    {{gitRepo.name}}
                </p>
                <p class="card-text text-font-size regular-ff" style="text-align: justify;">
                    {{gitRepo.description}}
                </p>
                <p class="card-text sub-text-font-size regular-ff font-color-ash">
                    {{gitRepo.language}}
                </p>
            </div>
            <div class="card-footer sub-text-font-size" style="text-align:center">
                <a style="cursor: pointer; text-decoration: none;" class="text-success" target="_blank"
                    href="{{gitRepo.repoLink}}">
                    View Repository
                </a>
            </div>
        </div>
        <br />
    </div>

    <div style="text-align: center;">
        <a type="button" class="btn btn-outline-success" href="https://github.com/iamchandanys?tab=repositories"
            target="_blank">
            View More
        </a>
    </div>

    <br />
    <hr /><br />

</div>