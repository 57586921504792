<app-get-in-touch-modal></app-get-in-touch-modal>

<div data-aos="fade" data-aos-duration="600" data-aos-delay="100" data-aos-easing="ease-in-sine" data-aos-once="true">
    <div>
        <div style="text-align: center;">
            <img src="../../assets/images/ProfilePicture.jpg" style="width: 160px;" />
        </div>

        <br />

        <div>
            Hi, I'm
        </div>

        <h1 class="text-success">CHANDAN Y S</h1>

        <p class="text-muted regular-ff" style="text-align: justify; font-size: 14px;">
            an experienced Full Stack Developer from Bengaluru, India, with over 5 years of expertise in Angular, React,
            .Net, Node JS, and Azure.
        </p>

        <div style="padding: 5px;">
            <button type="button" class="btn btn-outline-success text-font-size" (click)="onClickGetInTouch()">
                Get In Touch
            </button>
            &nbsp;
        </div>
    </div>
</div>

<br />
<hr />
<br />