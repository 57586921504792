<div class="row">
    <div class="col-md-4"></div>

    <div class="col-md-4">
        <div style=" position: fixed; bottom: 0;">
            <a href="mailto:imchandanys@gmail.com">
                <span id="mymail" class="text-secondary">
                    iamchandanys@gmail.com
                </span>
            </a>

            <br /><br />

            <div style="border-left: 2px solid #e0e8e0; height: 100px; margin-left: 11px;"></div>
        </div>
    </div>

    <div class="col-md-4"></div>
</div>