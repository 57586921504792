<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Get In Touch</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-9">
                        <p class="regular-ff text-font-size">
                            <!-- Gmail Logo SVG -->
                            <span class="text-secondary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                </svg>
                            </span>
                            &nbsp;iamchandanys@gmail.com
                        </p>
                    </div>
                    <div class="col-3" style="text-align: right;">
                        <!-- Copy SVG -->
                        <span style="cursor: pointer;" [class.text-success]="mouseOveredMailCopy"
                            (mouseover)="mouseOveredMailCopy=true" (mouseout)="mouseOveredMailCopy=false"
                            (click)="onClickCopyEmail('iamchandanys@gmail.com')">
                            <svg width="16" height="16" viewBox="0 0 16 16" class="bi bi-clipboard" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                <path fill-rule="evenodd"
                                    d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                            </svg>
                        </span>
                        &nbsp;
                        <!-- Message SVG -->
                        <a href="mailto:iamchandanys@gmail.com" style="color: black;">
                            <span style="cursor: pointer;" [class.text-success]="mouseOveredMail"
                                (mouseover)="mouseOveredMail=true" (mouseout)="mouseOveredMail=false">
                                <svg width="16" height="16" viewBox="0 0 16 16" class="bi bi-chat-left"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M14 1H2a1 1 0 0 0-1 1v11.586l2-2A2 2 0 0 1 4.414 11H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="alert alert-success mr-auto copy-mail-success-alert" role="alert"
                    *ngIf="displayMailCopiedTxt">
                    Mail ID Copied!
                </div>

                <button type="button" class="btn btn-success" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>