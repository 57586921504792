<app-splash-screen *ngIf="showSplashScreen"></app-splash-screen>

<div *ngIf="!showSplashScreen" class="container-fluid">

    <div class="row">
        <div class="col-md-2 d-none d-md-block">
            <app-social-media-icons></app-social-media-icons>
        </div>

        <div class="col-12 col-md-8">

            <br/>

            <app-about-me></app-about-me>

            <app-experience></app-experience>

            <app-lang-known></app-lang-known>

            <app-my-git-repo></app-my-git-repo>

            <app-get-in-touch></app-get-in-touch>

            <app-footer></app-footer>

            <br />
        </div>

        <div class="col-md-2 d-none d-md-block">
            <app-mail-link></app-mail-link>
        </div>
    </div>

</div>