<div data-aos="fade-up" data-aos-duration="600" data-aos-delay="600" data-aos-easing="ease-in-sine"
  data-aos-anchor-placement="top-bottom" data-aos-once="true">
  <p class="heading-font-size">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-folder" fill="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z" />
      <path fill-rule="evenodd"
        d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z" />
    </svg>
    &nbsp; Work Experience
  </p>

  <br />

  <div class="card">
    <div class="card-header">
      <div class="card-header-custom">
        <h4 class="card-title sub-heading-font-size Company-Name">
          Technical Lead at
          <span>
            <a style="text-decoration: none; cursor: pointer; color: #0c6d5c" href="https://www.briisk.io/"
              target="_blank">
              <span style="color: #d71e5c">b</span><span style="color: #62bc9f">riisk</span>
            </a>
          </span>
        </h4>
        <p class="card-subtitle mb-4 text-muted sub-text-font-size Company-Duration">
          May 2022 - Present
        </p>
      </div>
    </div>
    <div class="card-body">
      <!-- Project 1 -->
      <div style="text-align: justify">
        <p class="sub-heading-font-size">
          <span> Brand Label </span>
        </p>
        <h6 class="mb-4 text-muted sub-text-font-size Project-Duration">
          May 2022 - Present
        </h6>
        <p class="text-font-size regular-ff">
          Brand Label is an InsurTech platform that assists clients in automating processes, digitalizing their
          offerings, fostering innovation in products, and exploring fresh distribution channels. This leads to cost
          reduction, revenue growth, and enhanced value delivery for their customers.
        </p>
        <p class="text-font-size regular-ff">
          <span class="regular-ff"> Technologies used: </span>
          <span class="text-muted regular-ff" style="margin-top: -8px">
            React, Angular, .Net Core, MSSQL, Azure.
          </span>
        </p>
      </div>
    </div>
  </div>
  <br />
  <div class="card">
    <div class="card-header">
      <div class="card-header-custom">
        <h4 class="card-title sub-heading-font-size Company-Name">
          Developer at
          <span>
            <a style="text-decoration: none; cursor: pointer; color: #f95b30" href="https://www.udmglobal.com/"
              target="_blank">
              UDM Global Solution
            </a>
          </span>
        </h4>
        <p class="card-subtitle mb-4 text-muted sub-text-font-size Company-Duration">
          Jan 2018 - Apr 2022
        </p>
      </div>
    </div>
    <div class="card-body">
      <!-- Project 1 & 3 -->
      <div style="text-align: justify">
        <p class="sub-heading-font-size">
          <span> DifuzeGo </span>
        </p>
        <h6 class="mb-4 text-muted sub-text-font-size Project-Duration">
          Jan 2018 - June 2019 & Nov 2021 - Apr 2022
        </h6>
        <p class="text-font-size regular-ff">
          Difuzego is the content archiving and management platform allows to
          find, share and deliver content anywhere, anytime! It's the
          application for Canadian and international content producers and
          distributors to create, version, and deliver their content to their
          clients, worldwide.
        </p>
        <p class="text-font-size regular-ff">
          <span class="regular-ff"> Technologies used: </span>
          <span class="text-muted regular-ff" style="margin-top: -8px">
            Angular, Node JS, AWS.
          </span>
        </p>
      </div>
      <hr />
      <!-- Project 2 -->
      <div style="text-align: justify">
        <p class="sub-heading-font-size" style="text-decoration: none">
          KMAway
        </p>
        <h6 class="mb-4 text-muted sub-text-font-size Project-Duration">
          July 2019 - Oct 2021
        </h6>
        <p class="text-font-size regular-ff">
          With a small team, built full stack e-commerce websites and mobile
          applications that supports all the nearby stores where the users can
          buy grocery items, dairy products, vegetables etc from their nearby
          stores. The idea behind the project is to encourage and help every
          small vendors who are facing a tough competition from big e-commerce
          companies.
        </p>
        <p class="text-font-size regular-ff"></p>
        <p class="text-font-size regular-ff">
          <span class="regular-ff"> Technologies used: </span>
          <span class="text-muted regular-ff" style="margin-top: -8px">
            Xamarin, Angular, .net Core, MSSQL and COSMOS DB, Azure.
          </span>
        </p>
      </div>
    </div>
  </div>

  <br />
  <hr />
  <br />
</div>