<!-- For greater than medium screen -->

<div class="row d-none d-md-block main-div-style">
  <div class="col-lg-12">
    <img
      class="block-chain-cover-pic-style"
      src="../../../../assets/images/block_chain_project_cover_pic.png"
    />

    <div class="view-project-style">
      <p class="sub-heading-font-size">Blockchain Technology</p>
      <p class="text-font-size regular-ff">
        A <span class="text-success"><b>Blockchain</b></span> is a digitally
        <span class="text-info">distributed, decentralized, public ledger</span>
        that exists across a network. The goal of blockchain is to allow digital
        information to be recorded and distributed, but not edited. In this way,
        a blockchain is the foundation for immutable ledgers, or records of
        transactions that cannot be
        <span class="text-info">altered, deleted,</span> or
        <span class="text-info">destroyed</span>.
      </p>
      <button
        type="button"
        class="btn btn-outline-success"
        (click)="viewLiveDemo()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-box"
          viewBox="0 0 16 16"
        >
          <path
            d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"
          />
        </svg>
        View Demo
      </button>
    </div>
  </div>
</div>

<!-- For Lesser than medium screen -->

<div class="row d-block d-md-none text-center">
  <div class="col-lg-12">
    <p class="sub-heading-font-size">Blockchain Technology</p>
    <p class="text-font-size regular-ff">
      A <span class="text-success"><b>Blockchain</b></span> is a digitally
      <span class="text-info">distributed, decentralized, public ledger</span>
      that exists across a network. The goal of blockchain is to allow digital
      information to be recorded and distributed, but not edited. In this way, a
      blockchain is the foundation for immutable ledgers, or records of
      transactions that cannot be
      <span class="text-info">altered, deleted,</span> or
      <span class="text-info">destroyed</span>.
    </p>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="viewLiveDemo()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-box"
        viewBox="0 0 16 16"
      >
        <path
          d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"
        />
      </svg>
      View Live Demo
    </button>
  </div>
</div>
