<div class="vertical-container" style="height: 100vh; background-color: white; vertical-align: middle;">
    <div class="vertical-center" style="top: 42%;">
        <div style="width: 100vw; text-align: center;">
            <img data-aos="fade" data-aos-duration="1500" data-aos-delay="0" data-aos-easing="ease-in-sine"
                data-aos-once="true" src="../../../assets/images/logo-with-green-color.png" width="60px" />

            <!-- <hr width="14%" data-aos="fade" data-aos-duration="1500" data-aos-delay="0" data-aos-easing="ease-in-sine"
                data-aos-once="true" />

            <h5 data-aos="fade" data-aos-duration="1500" data-aos-delay="0" data-aos-easing="ease-in-sine"
                data-aos-once="true" style="color: #116c50; font-weight: 700;">
                ಚಂದನ್ ವೈ ಎಸ್
            </h5> -->
        </div>
    </div>
</div>