<app-get-in-touch-modal></app-get-in-touch-modal>

<div data-aos="fade-up" data-aos-duration="600" data-aos-delay="600" data-aos-easing="ease-in-sine"
  data-aos-anchor-placement="top-bottom" data-aos-once="true">
  <p class="heading-font-size">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-folder" fill="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z" />
      <path fill-rule="evenodd"
        d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z" />
    </svg>
    &nbsp; What's Next?
  </p>

  <div>
    <div class="row">
      <div class="col-md-2"></div>

      <div class="col-md-8" style="text-align: center; padding: 15px">
        <p class="sub-heading-font-size">Get In Touch</p>
        <p class="regular-ff text-font-size">
          Although I'm currently not looking for any new opportunities, my inbox
          is always open. Whether you have a question or just want to say hi,
          I'll try my best to get back to you.
        </p>
        <div>
          <button type="button" class="btn btn-outline-success" (click)="onClickGetInTouch()">
            Say Hello
          </button>
        </div>
      </div>

      <div class="col-md-2"></div>
    </div>
  </div>

  <br />
  <hr />
  <br />
</div>